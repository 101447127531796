<template>
    <b-modal ref="modal-cancel" v-model="computedShow" no-close-on-backdrop :title="$translate(`${cArgs.type}.cancel_sign_confirmation`)">
        <ValidationObserver ref="form" >
            <label class="app-input-top-label">Geef een reden voor afwijzing</label>
            <app-memo v-model="description" :maxLength="500" :lettersCounter="true" validatorRules="required" validatorName="Reden voor ontslag" />
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">Annuleren</app-button>
            <app-button @click="cancelDocument" :loading="loading">
                Opslaan
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";


export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data(){
        return{
            loading: false,
            description: ''
        }
    },

    methods: {
        
        cancelDocument(){

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    
                    this.loading = true;

                    axios
                        .post("people/document-sign/cancelDocument/" + this.cArgs.token, {
                            description: this.description
                        })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.$emit('update:result', 1);
                        })
                        .catch((error) => {
                            this.loading = false;
                        })
                        .finally(() => {
                            this.loading = false;  
                            this.computedShow = false;                       
                        });
                }
            });



        },
        closeModal() {
            this.computedShow = false;
        },
    }
    
}
</script>
<style>
</style>