<template>
    <div>
        
        <div class="navbar-inner">

            <div>
                <h1>Stap {{ itemData.step }}</h1>
                <h4 class="subheader">{{$translate(`${this.itemData.type}.left_sidebar_header`)}}</h4>
            </div>

            <div class="step" v-bind:class="{ 'step-done': itemData.step > 1, 'step-active': itemData.step === 1 }">
                <div>
                    <div class="circle" v-if="itemData.step > 1"><b-icon icon="check2"></b-icon></div>
                    <div class="circle" v-else>1</div>
                </div>
                <div>
                    <div class="title" v-bind:class="{ 'title-active': itemData.step === 1 }">{{$translate(`${this.itemData.type}.left_sidebar_step_1`)}}</div>
                </div>
            </div>
            <div class="step" v-bind:class="{ 'step-done': itemData.step > 2, 'step-active': itemData.step === 2 }">
                <div>
                    <div class="circle" v-if="itemData.step > 2"><b-icon icon="check2"></b-icon></div>
                    <div class="circle" v-else>2</div>
                </div>
                <div>
                    <div class="title" v-bind:class="{ 'title-active': itemData.step === 2 }">{{$translate(`${this.itemData.type}.left_sidebar_step_2`)}}</div>
                </div>
            </div>
            
            
        </div>
        
    </div>
</template>
<script>

export default {
    components: {},

    props: ["itemData"],

    created(){},

    computed: {},
    
    data() {
        return {
        };
    },

    methods: {
    },

};
</script>

<style scoped>
/* Steps */
.step {
    position: relative;
    min-height: 62px;
    color: #0D7BB3;
}
.step + .step {
    /*margin-top: 1.5em*/
}
.step > div:first-child {
    position: static;
    height: 0;
}
.step > div:not(:first-child) {
    margin-left: 3.5em;
    padding-left: 1em;
}

.step.step-done {
    color: #0D7BB3;
}
.step.step-done .circle {
    background-color: #8DD7F7;
    border: solid 1px #8DD7F7;
    color: #fff;
    font-size: 5px;
}

.step.step-active {
    color: #0D7BB3;
}
.step.step-active .circle {
    background-color: #0D7BB3;
    color: #fff;
    font-weight: 500;
}

.step.step-active.step-with-substep {
    min-height: 60px;
}

/* Circle */

.step .circle {
    background: #fff;
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    border: solid 1px #0D7BB3;
    color: #0D7BB3;
    text-align: center;
    font-size: 21px;
    font-weight: normal;
}

/* Vertical Line */
.step .circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100px;
    width: 1px;
    border: 1px dotted #0D7BB3;
    z-index: -1;
}

.step:last-child .circle:after {
    display: none;
}

.step .title {
    line-height: 1.5em;
    font-weight: normal;
    font-size: 14px;
    padding-top: 10px;
    color: #000;
}

.step .title-active {
    font-weight: bold;
    color: #174066;
}

.step .b-icon {
    height: 21px;
    width: 21px;
    stroke-width: 5;
}

.substep .b-icon {
    height: 18px;
    width: 18px;
    stroke-width: 5;
}

/* substep */
.substep {
    position: relative;
    min-height: 40px;
    color: #0D7BB3;
}

.substep.substep-optional {
    min-height: 90px;
    padding-top: 50px;
}

.substep + .substep {
    /*margin-top: 1.5em*/
}
.substep > div:first-child {
    position: static;
    height: 0;
}
.substep > div:not(:first-child) {
    margin-left: 3.5em;
    padding-left: 1em;
}

.substep.substep-done {
    color: #0D7BB3;
}
.substep.substep-done .circle {
    background-color: #8DD7F7;
    border: solid 1px #8DD7F7;
    color: #fff;
    font-size: 5px;
}

.substep.substep-active {
    color: #0D7BB3;
}
.substep.substep-active .circle {
    background-color: #0D7BB3;
    color: #fff;
    font-weight: 500;
}

.substep .circle {
    background: #fff;
    position: relative;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    border: solid 1px #0D7BB3;
    color: #0D7BB3;
    text-align: center;
    font-size: 21px;
    font-weight: normal;
    margin-left: 5px;
}

/* Vertical Line */
.substep .circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 40px;
    width: 1px;
    border: 1px dotted #0D7BB3;
    z-index: -1;
}

.substep.circle-optional .circle:after {
    height: 100px;
}

.substep.circle-optional-last .circle:after {
    height: 50px;
}

/*.substep:last-child .circle:after {
    display: none;
}*/

.substep .title {
    line-height: 1.5em;
    font-weight: normal;
    font-size: 12px;
    padding-top: 5px;
    color: #1f627f;
}

.substep .title-active {
    font-weight: bold;
    color: #1f627f;
}

.substep .feather {
    height: 18px;
    width: 18px;
    stroke-width: 2;
}

.step:hover,
.substep:hover {
    /*background-color: rgb(207, 207, 207); 
  cursor: pointer;*/
}
</style>

