<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{$translate(`${this.itemData.type}.step2_header`)}}</h1>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                <span class="align-middle">Unsuccesvol opgeslagen</span>
            </div>
        </b-alert>

        <b-card>
            <b-row class="d-flex align-items-center">
                <b-col start>
                    <div class="app-card-profile-title mb-3">Handtekening plaatsen binnen onderstaand kader</div>
                </b-col>

                <b-col cols="auto" end>
                    <app-button type="outline-danger" @click="clearSignaturePad()">Handtekening verwijderen</app-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-1 mt-1">
                    <VueSignaturePad
                        id="signature"
                        width="100%"
                        height="300px"
                        ref="signaturePad"
                        :options="{
                            onBegin: () => {
                                $refs.signaturePad.resizeCanvas();
                            },
                            penColor: '#393185'
                        }"
                    />
                </b-col>
            </b-row>
        </b-card>
            
    </div>
</template>

<script>
import { VueSignaturePad } from "vue-signature-pad";

export default {
    components: {
        VueSignaturePad
    },

    props: ["itemData", "saveSignature", "showEmptySignaturePadAlert"],

    created(){
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },
    
    watch:{
        saveSignature() {
            
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            if(isEmpty){
                this.cItemData.is_empty = true;
            }else{
                this.cItemData.is_empty = false;
                this.cItemData.signature = data;
            }

            this.$emit("saveSignaturedDocument");
        },

        showEmptySignaturePadAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            unsuccess_dismiss_count_down: 0
        }
    },
    
    methods:{
        clearSignaturePad(){
            this.$refs.signaturePad.clearSignature();
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    }
};
</script>

<style></style>
<style lang="scss" scope>
#signature {
    border: 1px dashed #b5d8f3;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}
</style>
